/* eslint-disable no-nested-ternary */
import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { useTranslation } from 'next-i18next';

import AddNewReview from 'views/common/components/UI/MyReview/AddNewReview';
import Typography500 from 'views/common/components/UI/Typography500/Typography500';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';

const PREFIX = 'ReviewsTitle';

const classes = {
  titleContainer: `${PREFIX}-titleContainer`,
  titleHolder: `${PREFIX}-titleHolder`,
  title: `${PREFIX}-title`,
  titleSecond: `${PREFIX}-titleSecond`,
  starIcon: `${PREFIX}-starIcon`,
  dot: `${PREFIX}-dot`,
  reviewsCount: `${PREFIX}-reviewsCount`,
  addReview: `${PREFIX}-addReview`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.titleContainer}`]: {
    marginBottom: theme.spacing(4.25),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },

  [`& .${classes.titleHolder}`]: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  [`& .${classes.title}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.titleSecond}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.starIcon}`]: {
    color: theme.palette.warning.main,
    marginLeft: theme.spacing(0.5),
  },

  [`& .${classes.dot}`]: {
    fontSize: theme.typography.pxToRem(40),
    color: theme.palette.secondary.dark,
    margin: theme.spacing(0, 1),
    lineHeight: theme.typography.pxToRem(15),
    alignSelf: 'self-start',

    [theme.breakpoints.up('lg')]: {
      lineHeight: theme.typography.pxToRem(20),
    },
  },

  [`& .${classes.reviewsCount}`]: {
    color: theme.palette.secondary.light,
    whiteSpace: 'nowrap',
    fontSize: theme.spacing(1.75),
  },

  [`& .${classes.addReview}`]: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
}));

const ReviewsTitle = (props) => {
  const {
    title,
    rateAvg,
    reviewsCount,
    reviewOverlayData,
    className,
    reviewCountTitle,
    path,
  } = props;

  const { t } = useTranslation('fe_er_reviews_title');

  return (
    <Root className={className}>
      <Grid container spacing={2} className={classes.titleContainer}>
        <Grid item xs={12} md={8}>
          <div className={classes.titleHolder}>
            {title && (
              <div className={classes.title}>
                <Typography500 variant="h3">{title}</Typography500>
              </div>
            )}

            <div className={classes.titleSecond}>
              {rateAvg && (
                <>
                  <Typography500 variant="h4">{rateAvg}</Typography500>
                  <StarIcon className={classes.starIcon} />
                  <span className={classes.dot}>.</span>
                </>
              )}

              <Typography component={path && NavigatorLink} href={path}>
                <span className={classes.reviewsCount}>
                  {reviewsCount > 0 ? (
                    <>
                      {reviewsCount}
                      {' '}
                      {t('fe_er_reviews_title:reviews')}
                    </>
                  ) : path ? (
                    reviewCountTitle
                  ) : (
                    t('fe_er_reviews_title:no_reviews')
                  )}
                </span>
              </Typography>
            </div>
          </div>
        </Grid>
        {reviewOverlayData && (
          <Grid item xs={12} md={4} className={classes.addReview}>
            <AddNewReview reviewOverlayData={reviewOverlayData} />
          </Grid>
        )}
      </Grid>
    </Root>
  );
};

export default ReviewsTitle;
