import React from 'react';
import { useTranslation } from 'next-i18next';
import { Grid, Typography, Rating } from '@mui/material';
import StyledBox from 'views/common/components/UI/DetailedRating/DetailedRating.styles';
import StarIcon from '@mui/icons-material/Star';

const DetailedRating = (props) => {
  const {
    className, rateValue, ratingComponentProps, onDataChange,
  } = props;
  const { t } = useTranslation('fe_er_common_detailed_rating');
  const reviewData = [
    {
      title: t('fe_er_common_detailed_rating:rider_comfort'),
      value: rateValue[0],
    },
    {
      title: t('fe_er_common_detailed_rating:passenger_comfort'),
      value: rateValue[1],
    },
    {
      title: t('fe_er_common_detailed_rating:luggage'),
      value: rateValue[2],
    },
  ];

  return (
    <StyledBox className={className}>
      <Grid container className="RScont">
        {reviewData.map((item, index) => (
          <Grid item key={item.title} className="RSitem">
            <Typography component="legend" variant="body2">
              {item.title}
            </Typography>
            <Rating
              size="small"
              name={index === 0 ? 'star_rating' : `star_rating_${index + 1}`}
              value={item.value}
              onChange={(e) => {
                onDataChange(e);
              }}
              emptyIcon={<StarIcon opacity="0.55" fontSize="inherit" />}
              {...ratingComponentProps}
            />
          </Grid>
        ))}
      </Grid>
    </StyledBox>
  );
};
export { DetailedRating };
