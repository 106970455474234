/* eslint-disable mui-unused-classes/unused-classes */
import React from 'react';
import { styled } from '@mui/material/styles';
import { FormHelperText, Box } from '@mui/material';
import Alert from '@mui/material/Alert';

const PREFIX = 'FormErrors';

const classes = {
  errorMsg: `${PREFIX}-errorMsg`,
  baseError: `${PREFIX}-baseError`,
  itemError: `${PREFIX}-itemError`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.errorMsg}`]: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.error.main,
    lineHeight: theme.typography.pxToRem(23),
    '& a': {
      color: theme.palette.error.main,
    },
  },

  [`& .${classes.baseError}`]: {
    margin: 0,
    paddingLeft: theme.spacing(2),
  },

  [`& .${classes.itemError}`]: {
    display: 'block',
  },
  '& .MuiAlert-filledError': {
    color: theme.palette.primary.contrastText,
    fontSize: '14px',
    '& .MuiAlert-icon': {
      fontSize: '22px',
      marginRight: '0.5rem',
    },
  },
}));

const FormErrors = ({ errorMsgs, showBaseErrors, errorField }) => {
  if (!errorMsgs) return null;

  return (
    <Root>
      {showBaseErrors ? (
        <>
          {((errorMsgs.errors
            && errorMsgs.errors.base
            && errorMsgs.errors.base.length > 0)
            || errorMsgs.error_message) && (
            <Box mb={4}>
              <Alert variant="filled" severity="error">
                <ul className={classes.baseError}>
                  {errorMsgs.error_message && (
                    <li>{errorMsgs.error_message}</li>
                  )}
                  {errorMsgs.errors.base
                    && errorMsgs.errors.base.map((err) => (
                      <li key={err}>{err}</li>
                    ))}
                </ul>
              </Alert>
            </Box>
          )}
        </>
      ) : (
        <>
          {errorField ? (
            <FormHelperText error>
              {errorMsgs.errors
                && errorMsgs.errors[errorField]
                && Array.isArray(errorMsgs.errors[errorField])
                && errorMsgs.errors[errorField].map((error) => (
                  <span className={classes.itemError} key={error}>
                    {error}
                  </span>
                ))}
            </FormHelperText>
          ) : (
            <FormHelperText error>
              {errorMsgs.errors
                && Array.isArray(Object.keys(errorMsgs.errors))
                && Object.keys(errorMsgs.errors).map((err) => (
                  <React.Fragment key={err}>
                    {err !== 'base'
                      && Array.isArray(errorMsgs.errors[err])
                      && errorMsgs.errors[err].map((error) => (
                        <span className={classes.itemError} key={error}>
                          {error}
                        </span>
                      ))}
                  </React.Fragment>
                ))}
            </FormHelperText>
          )}
        </>
      )}
    </Root>
  );
};

export default FormErrors;
export { FormErrors };
