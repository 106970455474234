import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .RScont': {
    flexWrap: 'nowrap',
  },
  '& .RSitem': {
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    flex: '1 1 20%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '&:not(:last-child)': {
      '&:before': {
        content: '""',
        position: 'absolute',
        backgroundColor: theme.palette.grey[100],
        width: '1px',
        height: '90%',
        transform: 'translateY(-50%)',
        top: '50%',
        right: '0',
      },
    },
    '& .MuiRating-iconFilled': {
      color: theme.palette.warning.main,
    },

    '& .MuiSvgIcon-root': {
      [theme.breakpoints.down('lg')]: {
        width: theme.spacing(1.6),
        height: theme.spacing(1.6),
      },
    },
  },
}));
export default StyledBox;
